import styles from './About.css'
import Animated from '../ui/Animated'

export default function Home() {
	return (
	<div className="about">
			<div className='container'>
			<Animated delay={0}>
				<div className="block">
					<p className="text">
						Мы - одни из первых, кто начал продвигать породу маламутов в России.
					</p>
					<p className="text">
						Начиная с 2009, наши юные птенцы нашли более 50 домов, а ветераны смогли поучаствовать в международных чемпионатах и выставках собак, получить множество наград и обеспечить своим детям достойную родословную.
					</p>
					<p>
						Сейчас питомник располагается под Уфой, столицей Башкирии, и насчитывает около 15 собак, среди которых 7 годовалых щенков ищут себе новый дом.
					</p>
				</div>
			</Animated>
			</div>
	</div>)
}