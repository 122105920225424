import styles from './Albums.css'
import Animated from '../ui/Animated'

export default function Albums() {
	
	return (<Animated>
	<div className="dev">
			<h1>В разработке!</h1>
		
	</div></Animated>)
}