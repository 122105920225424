import styles from './Navbar.css'

export default function Navbar({ value, moveTo }) {
	return (
		<nav>
			<a className={value === 0 || "disabled"} onClick={() => moveTo(0)}>
				Главная
			</a>
			<span>{"-"}</span>
			<a className={value === 1 || "disabled"} onClick={() => moveTo(1)}>
				О нас
			</a>
			<span>{"-"}</span>
			<a className={value === 2 || "disabled"} onClick={() => moveTo(2)}>
				Галерея
			</a>
		</nav>
	)
}