import styles from './Home.css'
import { ReactSVG } from 'react-svg'
import Animated from '../ui/Animated'

export default function Home() {
	return (
	<div className="home">
		<div className="center">
		<Animated delay={0}>
		<h1>Stellar Road</h1>
		</Animated>
		<Animated delay={200}>
		<div className="description">
			<p>Питомник маламутов</p>
			<p>из Башкирии</p>
		</div>
		</Animated>
		<Animated delay={400} className="spec">
		<p className="spec">В наличии подростки маламута!</p>
		</Animated>
		</div>
		
		<div className="links">
			<a href="https://vk.com/malsr"><img src="icon/vk.svg"/></a>
			<a href="https://www.facebook.com/a igul.kamaletdinova"><img src="icon/fb.svg"/></a>
			<a style={{ marginBottom: '17px', paddingRight: '20px', opacity: '0.5' }}><img src="icon/wa.svg"/></a>
		</div>
	</div>)
}