//import logo from './logo.svg';

import { useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views';

import './App.css';

import Home from "./pages/Home"
import About from "./pages/About"
import Albums from "./pages/Albums"

import Navbar from "./ui/Navbar"
import Background from "./pages/Background"

function App() {
	const [ value, setValue ] = useState(0)
	const [ selected, setSelected ] = useState("")
	
	const moveTo = index => {
		setValue(index)
	}
	
	const switched = (index, type) => {
		if(type === 'end') moveTo(index)
	}
	
	return (
	<div style={container}>
		<Background value={value}/>
		<Navbar value={value} moveTo={moveTo} />
		<SwipeableViews enableMouseEvents 
			style={{ height: '100%' }} 
			containerStyle={{ height: '100%' }} 
			index={value} onSwitching={switched}>
			<Home />
			<About />
			<Albums />
		</SwipeableViews>
	</div>
	)
}

const view = {
	height: '100%'
}

/*
<Routes>
	<Route path="/" element={<Home />} />
	<Route path="/about" element={<About />} />
	<Route path="/albums" element={<Albums />} />
</Routes>
*/

const container = { 
	width: '100%',
	height: '100%',
	padding: 0,
	margin: 0,
	position: 'absolute',
	zIndex: '-1' // Z INDEX!!!
}

export default App;
