import styles from './Background.css'
import { motion, AnimatePresence } from 'framer-motion'

const animations = max => {
	return {
		initial: { opacity: 0 },
		animate: { opacity: max },
		exit: { opacity: 0 }
	}
}

const gradients = [
	"linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 250, 240, 0.15) 100%)",
	"linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(189, 142, 197, 0.20) 100%)"
]

const gradient = grad => {
	return {
		initial: { background: '' },
		animate: { background: gradients[grad] },
		exit: { background: '' }
	}
}

export default function Background({ value }) {
	let url, grad = null
	
	switch (value) {
		case 0:
			url = "maxrun.jpg";
			grad = 0;
			break;
		case 1:
			url = "boy.jpg";
			grad = 1;
			break;
		case 2:
			url = "max.jpg";
			grad = 1;
			break;
	}
	
	return (<>
		
		<AnimatePresence>
			<motion.img
				className="background image"
				style={ value === 0 ? { objectPosition: '35% 50%' } : ''}
				key={url}
				src={url}
				{...animations(0.3)}
			/>
		</AnimatePresence>
		
		
		<motion.div
			className="background"
			key={grad}
			style={{
				background: gradients[grad]
			}}
			variants={animations(1)} initial="initial" animate="animate" exit="exit"
		/>
		
		<div
			className="background"
			style={{
				backgroundColor: '#505050',
				zIndex: '-5'
			}}
		/>
		
		
		{/*<div className="background" style={gradient}></div>*/}</>)
}

/*const gradient = {
	background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(0, 250, 240, 0.15) 100%)',
}*/

/*const image = url => {
	return {
		background: 'url(' + url + ') lightgray 50% / cover no-repeat',
		opacity: '0.3'
	}
}*/