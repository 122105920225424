import { motion } from 'framer-motion'

const animations = (delay, maxOpacity) => {
	return {
	initial: { opacity: 0, y: (100 + delay / 6) },
	animate: { opacity: maxOpacity ?? 1, y: 0},
	exit: { opacity: 0, y: (-100 + delay / 2) }
	}
}

const Animated = ({children, delay, maxOpacity}) => {
	return (
		<motion.div variants={animations(delay, maxOpacity)} initial="initial" animate="animate" exit="exit">
			{children}
		</motion.div>
	)
}

export default Animated